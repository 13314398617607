import { UserRole } from "../entities/User";
import { Client } from "../entities/Client";

export type AuthUser = {
  uid: string;
  idUser: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: Record<UserRole, number[]>;
  sealedSession: string;
  phoneNumber?: string;
  mfa?: UserMFA;
  clients: Pick<Client, "idClient" | "name">[];
};

export type MFAMethodOptions = "totp" | "sms";
export enum MFAMethods {
  totp = "totp",
  sms = "sms",
}

export type MFAFactor = {
  idFactor: string;
  type: MFAMethodOptions;
};

export type MFAChallenge = {
  idChallenge: string;
  type: MFAMethodOptions;
};

export type UserMFA = {
  factor?: any;
  challenge?: any;
  isRequired?: boolean;
  isPassed?: boolean;
};

export type AuthenticateWithEmailAndPasswordResponse = {
  success: boolean;
  mfaType?: MFAMethodOptions;
  message?: string;
};
