import { useEffect, useRef, useState } from "react";
import {
  JobRevisionsRequestParams,
  JobWithData,
  JobStatus,
} from "@sumit-platforms/types";
import JobService from "../services/jobService";
import { defaultJobRevisionsQuery } from "../constants/default-queries";

interface JobSettings {
  subtitlesValidation: any | Record<any, any>;
  peaks: any | null;
}

export const useJob = ({
  idJob,
  filename = "data.json",
  config,
}: {
  idJob: number;
  filename?: string;
  config: any;
}) => {
  const jobService = JobService({ config });
  const [job, setJob] = useState<JobWithData | null>(null);
  const jobSettings = useRef<JobSettings>({
    subtitlesValidation: {},
    peaks: null,
  }).current;
  const [error, setError] = useState<null | string>(null);

  const fetchDataInterval = useRef<NodeJS.Timeout | null>(null);

  const init = async () => {
    const getSubtitlesValidationPromise = getSubtitlesValidation();
    const getJobPeaksPromise = getJobPeaks();
    const getJobPromise = getJob();

    await Promise.all([
      getSubtitlesValidationPromise,
      getJobPeaksPromise,
      getJobPromise,
    ]);
  };

  const getJob = async () => {
    if (!idJob) return;
    try {
      const newJob = await jobService.getJob(idJob);
      setJob(newJob as JobWithData);
      return newJob;
    } catch (e: any) {
      console.error(`Failed to load job data for job ${idJob}`);
      setError(e.response.data.message);
      throw new Error(e);
    }
  };

  const getJobRevisions = async (query?: JobRevisionsRequestParams) => {
    if (!idJob) return;
    const revision = await jobService.getJobRevisions({
      excludeSaveTriggers:
        query?.excludeSaveTriggers ||
        defaultJobRevisionsQuery.excludeSaveTriggers,
      idJob,
    });
    return revision;
  };

  const getJobPeaks = async () => {
    const peaks = await jobService.getJobPeaks(idJob);
    jobSettings.peaks = peaks.peaks;
  };

  const getSubtitlesValidation = async () => {
    const subtitlesValidation = await jobService.getSubtitlesValidationSettings(
      idJob
    );
    jobSettings.subtitlesValidation = subtitlesValidation;
  };

  const fetchRecordingData = async () => {
    if (!idJob) return;
    try {
      const updatedJob = await jobService.getJob(idJob);
      console.log(updatedJob.status);
      const recording = [JobStatus.kit_recording, JobStatus.stt].includes(
        updatedJob.status
      );
      console.log({ recording });
      if (!recording) {
        stopFetching();
      } else if (job && updatedJob.data?.ranges) {
        setJob(updatedJob as JobWithData);
      }
    } catch (error) {
      console.error("Failed to fetch updated job data:", error);
    }
  };

  const startFetching = () => {
    console.log("startFetching");
    if (!fetchDataInterval.current) {
      fetchDataInterval.current = setInterval(fetchRecordingData, 5000);
    }
  };

  const stopFetching = () => {
    console.log("stopFetching");
    if (fetchDataInterval.current) {
      clearInterval(fetchDataInterval.current);
      fetchDataInterval.current = null;
    }
  };

  useEffect(() => {
    if (job && [JobStatus.kit_recording, JobStatus.stt].includes(job.status)) {
      startFetching();
    }
    return stopFetching;
  }, [job?.status]);

  useEffect(() => {
    if (job) return;
    init();
  }, [job]);

  return {
    job,
    getJob,
    setJob,
    getJobPeaks,
    getJobRevisions,
    jobSettings,
    error,
  };
};
